import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

export type FetchMode = 'single' | 'foveated' | 'all';

type State = {
  bearing: number;
  setBearing: (b: number) => void;
  altitude: number;
  setAltitude: (a: number) => void;
  zoom: number;
  setZoom: (z: number) => void;
  fetchMode: FetchMode;
  setFetchMode: (a: FetchMode) => void;
};

export const useStore = create<State>()(
  subscribeWithSelector((set) => ({
    bearing: 0,
    setBearing: (bearing) => set({ bearing }),
    altitude: 0,
    setAltitude: (altitude) => set({ altitude }),
    zoom: 35,
    setZoom: (zoom) => set({ zoom }),
    fetchMode: 'foveated',
    setFetchMode: (fetchMode) => set({ fetchMode }),
  }))
);
