import { css } from '@emotion/css';

import { useStore } from '../../state';
import AzimuthIndicator from './AzimuthIndicator';
import BearingSlider from './BearingSlider';
import DemoWarning from './DemoWarning';
import ElevationIndicator from './ElevationIndicator';
import ImageFetchMode from './ImageFetchMode';
import StationInfo from './StationInfo';
import ZoomSlider from './ZoomSlider';

type ControlsProps = {
  stationId: number;
  showStationInfo: boolean;
  showAzimuthIndicator: boolean;
  showElevationIndicator: boolean;
  bearing: number;
  elevation: number;
  isDemo?: boolean;
  showWarning?: boolean;
};

export default function Controls({
  stationId,
  showStationInfo,
  showAzimuthIndicator,
  showElevationIndicator,
  bearing,
  elevation,
  isDemo,
  showWarning,
  showZoomSlider,
  showPanSlider,
}: ControlsProps) {
  const fetchMode = useStore((state) => state.fetchMode);
  const setFetchMode = useStore((state) => state.setFetchMode);
  const zoom = useStore((state) => state.zoom);
  const setZoom = useStore((state) => state.setZoom);
  const setBearing = useStore((state) => state.setBearing);
  return (
    <div className={style}>
      {showStationInfo && <StationInfo stationId={stationId} isDemo={isDemo} />}
      {showAzimuthIndicator && <AzimuthIndicator azimuth={bearing} />}
      {showElevationIndicator && <ElevationIndicator elevation={elevation} />}
      <ImageFetchMode mode={fetchMode} onChange={setFetchMode} />
      {/* <Attribution /> */}
      {isDemo && showWarning && <DemoWarning />}
      {showZoomSlider && (
        <ZoomSlider zoom={zoom} onChange={(z) => setZoom(z)} />
      )}
      {showPanSlider && (
        <BearingSlider bearing={bearing} onChange={(b) => setBearing(b)} />
      )}
    </div>
  );
}

const style = css({
  fontFamily: 'Open Sans',
});
